/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import React from 'react';

import 'url-polyfill';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';
// import { theme } from '@cleverrealestate/clever-components';
// import 'typeface-montserrat';
// import 'typeface-dm-sans';
// import React, { Profiler } from 'react';
// const MUITheme = createMuiTheme(theme);

require('./src/css/typography.css');
const React = require('react');
const { createStore } = require('redux-dynamic-modules');
const { getThunkExtension } = require('redux-dynamic-modules-thunk');
const { Provider } = require('react-redux');

export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore({
    extensions: [getThunkExtension()],
  });
  return <Provider store={store}>{element}</Provider>;
};

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};

// export const wrapPageElement = ({ element, props }) => (
//   // See https://reactjs.org/docs/profiler.html#onrender-callback for onRender parameters
//   <Profiler id={props.someUniqueId} onRender={capturePageMetrics}>
//     {element}
//   </Profiler>
// );

// eslint-disable-next-line
// export const wrapRootElement = ({ element }) => {
//   return (
//     <ThemeProvider theme={MUITheme}>
//       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//         {element}
//       </MuiPickersUtilsProvider>
//     </ThemeProvider>
//   );
// };
